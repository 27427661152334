<template>
  <div class="page">
    <div class="title">离职管理</div>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="search.keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select" @click="toSearch">搜索</div>
        <div class="custom_button no_select" @click="dialogFormVisible = true">
          自离岗设置
        </div>
      </div>
    </div>

    <!-- cards -->
    <div class="cards_box">
      <div class="card" style="width: 470px">
        <div class="card_item">
          <div>待离职</div>
          <div>{{ statistics.status1 }}人</div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="card_item">
          <div>已离职</div>
          <div>{{ statistics.status2 }}人</div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="card_item">
          <div>可复岗</div>
          <div>{{ statistics.status3 }}人</div>
        </div>
      </div>
    </div>

    <!-- table -->
    <el-table
      ref="table"
      :data="tableData"
      :header-cell-style="tableHeaderStyle"
      :cell-style="tableCellStyle"
      style="width: 100%"
    >
      <el-table-column
        type="selection"
        width="55"
        :selectable="(row, index) => row.status === 1"
      >
      </el-table-column>
      <el-table-column prop="staff.real_name" label="姓名"> </el-table-column>
      <el-table-column prop="staff.job_number" label="工号"> </el-table-column>
      <el-table-column
        prop="staff.department_data.department_name"
        label="部门"
      >
      </el-table-column>
      <el-table-column prop="staff.department_data.duty_name" label="岗位">
      </el-table-column>
      <el-table-column prop="staff.labour_type_value" label="工作性质">
      </el-table-column>
      <el-table-column prop="staff.entry_time" label="入职时间">
      </el-table-column>
      <el-table-column prop="quit_type_value" label="离职类型">
      </el-table-column>
      <el-table-column prop="status_value" label="离职状态"> </el-table-column>
      <el-table-column prop="quit_time" label="申请离职时间"> </el-table-column>
      <el-table-column label="操作" width="120">
        <template v-slot:default="scope">
          <el-dropdown size="mini" split-button type="primary">
            操作
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="scope.row.status == 10"
                @click.native="toLeave([scope.row])"
                >办理离职</el-dropdown-item
              >
              <el-dropdown-item v-if="scope.row.status == 20"
                >评级</el-dropdown-item
              >
              <el-dropdown-item v-if="scope.row.status == 20"
                >复岗</el-dropdown-item
              >
              <el-dropdown-item
                v-if="scope.row.quit_type == 3"
                @click.native="autoLeave(scope.row)"
                >自离岗</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <div class="operations">
            <template v-if="scope.row.status == 10">
              <div @click="toLeave([scope.row])">办理离职</div>
            </template>
            <template v-if="scope.row.status == 20">
              <div>评级</div>
              <div>复岗</div>
            </template>
            <template v-if="scope.row.quit_type == 3">
              <div @click="autoLeave(scope.row)">自离岗</div>
            </template>
          </div> -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>

    <!-- <div class="bottom_betch_operation">
      <el-select class="betch_select" v-model="batch">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="custom_button no_select" @click="toBatchProcessing">批量处理</div>
    </div> -->

    <el-dialog title="自离岗设置" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="选择员工">
          <el-select
            v-model="form.staff_id"
            filterable
            remote
            reserve-keyword
            placeholder="请输入员工姓名"
            :remote-method="remoteMethod"
            :loading="dialog_loading"
          >
            <el-option
              v-for="item in dialog_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="离职时间">
          <el-date-picker
            v-model="form.quit_time"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="批量签署" width="40%" :visible.sync="dialogFormVisible2">
      <el-form ref="form2">
        <el-form-item label="签署合同">
          <el-select
            style="witdh: 100%"
            v-model="contracts"
            filterable
            multiple
            reserve-keyword
            placeholder="请选择要签署的合同"
          >
            <el-option
              v-for="item in contract_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="!item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="onBatchSignSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      search: {
        keyword: "",
      },
      form: {
        staff_id: "",
        quit_time: "",
      },
      rules: {
        staff_id: [
          {
            required: true,
            message: "请选择员工",
            trigger: "blur",
          },
        ],
        quit_time: [
          {
            required: true,
            message: "请选择离职时间",
            trigger: "blur",
          },
        ],
      },
      // 分页参数
      page: {
        size: 15,
        page: 1,
        total: 0,
      },
      tableData: [],
      dialog_options: [],
      statistics: {},
      dialogFormVisible: false,
      dialog_loading: false,
      dialogFormVisible2: false,
      contract_options: [],
      contracts: [],
      batch: 1,
      options: [
        {
          value: 1,
          label: "办理离职",
        },
      ],

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
    };
  },
  inject: ["reload"],
  created() {
    this.loadData = this.loadDataFactory();
    this.loadData();

    this.getLeaveEmployeeStatistics()
      .then((res) => {
        console.log(res);
        this.statistics = res.data.data;
      })
      .catch((e) => {
        console.log(e);
      });

    /**
     * 获取合同
     */
    this.getStandardContractList({
      page: 1,
      size: 10000,
    })
      .then((res) => {
        console.log(res);
        this.contract_options = res.data.data.list.map((item) => {
          return {
            label: item.title,
            value: item.template_id,
          };
        });
      })
      .catch((e) => {
        this.$message.error(e.msg);
      });
  },
  methods: {
    ...mapActions("employee", [
      "getEmployeeList",
      "getEmployeeLeaveList",
      "getLeaveEmployeeStatistics",
      "addAutoLeave",
      "postEmployeeLeave",
    ]),
    ...mapActions("contract", ["postBatchSign", "getStandardContractList"]),

    loadDataFactory(keyword = null) {
      let params = {};
      if (keyword) {
        params.keywords = keyword;
      }
      return () => {
        params.page = this.page.page;
        let loading = this.$loading();
        this.getEmployeeLeaveList(params)
          .then((res) => {
            console.log(res);
            this.tableData = res.data.data.list;
            this.page.total = res.data.data.total;
            this.$nextTick(() => {
              loading.close();
            });
          })
          .catch((e) => {
            this.$message.error(e.msg);
            loading.close();
          });
      };
    },

    /**
     * 搜索
     */
    toSearch() {
      this.page.page = 1;
      // 生产带有搜索参数的函数
      this.loadData = this.loadDataFactory(this.search.keyword);
      this.loadData();
    },

    remoteMethod(query) {
      if (query !== "") {
        this.dialog_loading = true;
        this.getEmployeeList({
          page: 1,
          keywords: query,
          status: 3,
        })
          .then((res) => {
            this.dialog_loading = false;
            console.log(res);
            this.dialog_options = res.data.data.list.map((item) => {
              return {
                label: item.real_name,
                value: item.id,
              };
            });
          })
          .catch((e) => {
            this.dialog_loading = false;
            console.error(e);
          });
      } else {
        this.dialog_options = [];
      }
    },

    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.dialogFormVisible = false;
          let loading = this.$loading();
          console.log(this.form);
          this.addAutoLeave(this.form)
            .then((res) => {
              console.log(res);
              loading.close();
              this.$message.success(res.data.msg);
              this.loadData();
            })
            .catch((e) => {
              console.log(e);
              loading.close();
              this.$message.error(e.msg);
            });
        } else {
          this.$notify.error({
            title: "提交失败",
            message: "请先完整填写表单",
          });
          return false;
        }
      });
    },

    /**
     * 自离岗
     */
    autoLeave(row) {
      this.$alert("需自行打印自离岗声明，并通过EMS寄给员工", "提示", {
        confirmButtonText: "确定",
        callback: () => {
          this.$router.push({
            name: "ReminderNotice",
            params: {
              id: row.id,
            },
          });
        },
      });
    },

    /**
     * 离职
     */
    toLeave(employee) {
      console.log(employee);
      this.selectedIds = employee.map((item) => item.id);
      this.selectedFddIds = employee.map((item) => item.fddUserInfo?.id);
      this.cantFddEmployee = employee.filter((item) => !item.fddUserInfo?.id);

      this.callback = () => {
        this.$confirm("确定办理离职吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let loading = this.$loading();
          this.postEmployeeLeave({
            ids: this.selectedIds.join(","),
          })
            .then((res) => {
              console.log(res);
              loading.close();
              this.$message.success(res.data.msg);
              this.loadData();
            })
            .catch((e) => {
              console.log(e);
              loading.close();
              this.$message.error(e.msg);
            });
        });
      };

      this.$confirm("是否下发签署合同?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          if (this.cantFddEmployee.length > 0) {
            this.$alert(
              `员工 ${this.cantFddEmployee
                .map((item) => item.real_name)
                .join("、")} 未注册法大大`,
              "无法签署",
              {
                confirmButtonText: "确定",
              }
            ).then(() => {
              this.callback();
            });
          } else {
            this.dialogFormVisible2 = true;
          }
        })
        .catch(() => {
          this.callback();
        });
    },

    /**
     * 批量处理
     */
    toBatchProcessing() {
      let selectedItems = this.$refs.table.store.states.selection;
      console.log(selectedItems);
      if (selectedItems.length > 0) {
        this.toLeave(selectedItems);
      } else {
        this.$message.warning("请至少选择一项进行操作");
      }
    },

    onBatchSignSubmit() {
      if (this.contracts.length == 0) {
        this.$notify.error({
          title: "提交失败",
          message: "请先选择合同",
        });
        return;
      }
      let loading = this.$loading();

      this.postBatchSign({
        template_ids: this.contracts.join(","),
        party_b_fdd_userids: this.selectedFddIds.join(","),
      })
        .then((res) => {
          console.log(res);
          loading.close();
          this.$message.success(res.data.msg);
          this.callback();
        })
        .catch((e) => {
          console.log(e);
          loading.close();
          this.$message.error(e.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  padding-bottom: 50px;
  min-height: calc(100% - 50px);
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_select {
      width: 80px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }

      ::v-deep .el-input__icon {
        line-height: 36px;
      }
    }

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
    > div:not(:first-child) {
      margin-left: 20px;
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.cards_box {
  display: flex;
  height: 100px;
  background-color: #fff;
  margin-bottom: 40px;
  justify-content: space-between;

  .card {
    display: flex;
    align-items: center;
    box-shadow: 0px 6px 30px 0px rgba(223, 227, 236, 0.4);
    border-radius: 4px;

    .el-divider {
      height: 70px;
    }

    .card_item {
      flex: 1;
      width: max-content;
      height: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #495060;
      font-size: 14px;

      & > div:nth-child(1) {
        margin-bottom: 15px;
      }

      &.sp {
        flex-direction: row;
        color: #4db9d5;
        font-size: 16px;
        position: relative;
        top: 3px;

        span {
          font-size: 30px;
          position: relative;
          bottom: 3px;
        }
      }
    }
  }
}

.operations {
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    font-size: 14px;
    color: #406bff;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 13px;
    }
  }
}

.bottom_betch_operation {
  position: absolute;
  bottom: 0;
  display: flex;

  .betch_select {
    width: 130px;
    height: 36px;
    margin-right: 10px;

    ::v-deep .el-input__inner {
      height: 36px;
    }

    ::v-deep .el-input__icon {
      line-height: 36px;
    }
  }
}
</style>
